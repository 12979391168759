
@font-face {
    font-family: "digitas-font";
    src: url("fonts/WhitneySSm-Black_Web.woff") format('woff'),
         url("fonts/WhitneySSm-BlackItalic_Web.woff") format('woff'),
         url("fonts/WhitneySSm-Bold_Web.woff") format('woff'), 
         url("fonts/WhitneySSm-BoldItalic_Web.woff") format('woff'),
         url("fonts/WhitneySSm-Book_Web.woff") format('woff'),
         url("fonts/WhitneySSm-BookItalic_Web.woff") format('woff'),
        url("fonts/WhitneySSm-Black_Web.woff2"),
        url("fonts/WhitneySSm-BlackItalic_Web.woff2"),
        url("fonts/WhitneySSm-Bold_Web.woff2"),
        url("fonts/WhitneySSm-BoldItalic_Web.woff2"),
        url("fonts/WhitneySSm-Book_Web.woff2"),
        url("fonts/WhitneySSm-BookItalic_Web.woff2");
         

}

@font-face {
    font-family: "digitas-fonts";
    src: url("fonts/WhitneySSm-BookItalic_Web.woff") format('woff');}

/********** Stepper Start *************/

/* body{
    font-family: digitas-font;
} */

.PaStepperProgress {
    position: absolute;
    width: 1167px;
    height: 32px;
    /* left: 545px;
  top: 302.781px; */
    overflow: visible;
}

.PaStepperProgressBar {
    position: absolute;
    width: 1167pxpx;
    height: 32px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#PaStepperGroup {
    position: absolute;
    width: 334px;
    height: 14px;
    left: 130px;
    top: 9px;
    overflow: visible;
}

.PaStepperPath {
    overflow: visible;
    position: absolute;
    width: 943px;
    height: 1px;
    left: 122.5px;
    top: 16.5px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#PaStepperPath {
    fill: rgba(0, 0, 0, 0);
    stroke: rgba(192, 192, 192, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

#PaStepperStage {
    position: absolute;
    width: 14px;
    height: 14px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

.PaStepperEllipse {
    /* position: absolute; */
    overflow: visible;
    width: 14px;
    height: 14px;
    left: 0px;
    top: 0px;
}

#PaStepperEllipse {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(192, 192, 192, 1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

/********** Stepper End *************/


.MuiStepLabel-iconContainer {
    padding-right: 0px !important;
}

.MuiStep-root {
    padding: 0px !important;
}

.loaderBackground {
    background: none repeat scroll 0 0 #2d383f99;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    overflow: hidden;
    z-index: 999998;
    margin-top: 70px;
}

.loaderStyle {
    position: absolute;
    left: 47%;
    width: 5%;
    top: 40%;
    z-index: 9999;
}


#operating-conditions-table td:nth-child(1) {
    width: 200px;
}


/* .text_input_wrapper{
    border: 1px red solid;
    width: 104%;
    border-radius: 5px;
} */

button[name|="hSizingResults"]
{
    width: 30% !important;
    float: right;
}

/********** Header Start *************/
.pa_header_root {
    flex-grow: 1;
}

.pa_header_menuButton {
    margin-right: 2;
}

.pa_header_container{
    z-Index: 2;
    top: 0;
    position: sticky;
    background-color: white;
}



.pa_header_title {
    flex-grow: 1;
}

.pa_header_header {
    color: var(--ddl-color--primary-black) !important;
    background-color: white !important;
    width: 100%;
    height: 84px;
    padding: 10px 0;
}

.pa_header_header_back {
    background-color: white;
    height: 84px;
    width: 100%;
    padding: 10px 0;
}

.pa_header_logo_title {
    cursor: pointer;
    width: 100px;
}

/********** Header End *************/

#demo-simple-select{
    width:55px;
}
.size-summary-input{
    margin-top: 10px;
}

.toggle-button-label{
    display: block;
    margin-top: 20px;
}

.custom-scrollbar {
    scrollbar-color: rgba(0,0,0,.2) transparent;
    scrollbar-color: var(--palette-black-alpha-20,rgba(0, 0, 0, .2)) transparent;
    scrollbar-width: thin
}


.custom-scrollbar::-webkit-scrollbar {
    width: 18px;
    height: 18px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    background: rgba(0,0,0,.2);
    border-radius: 10px;
    background-clip: padding-box
}

.custom-scrollbar::-webkit-scrollbar-corner {
    background: transparent
}

.custom-scrollbar::-webkit-scrollbar-thumb:vertical {
    min-height: 30px
}

.custom-scrollbar::-webkit-scrollbar-thumb:horizontal {
    min-width: 30px
}


.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,.3);
    background: rgba(0, 0, 0, .3);
    background-clip: padding-box;
    border: 4px solid transparent
}

.margin-bottom-3 {
    margin-bottom: 3px;
}

.margin-right-40 {
    margin-right: 40px;
}